<template>
  <div class="pay">
    <div class="pay-title">支付中心</div>
    <img
      class="pay-back"
      src="../assets/images/arrow-l.svg"
      @click="$router.push({ path: '/contractPayList', query: { contractId: contractId } })" />
    <div class="pay-body">
      <div class="pay-body-card pay-body-course">
        <div class="pay-body-card-title">菠萝在线国际教育</div>
        <div class="pay-body-course-item" v-for="(item, index) in course" :key="index">
          <div class="pay-body-course-item-l" :style="'background:' + colors[index % 5]">
            <img src="../assets/images/tv.svg" alt="" />
          </div>
          <div class="pay-body-course-item-r">
            <span>{{ item.serviceName }}</span>
            <div>
              <!-- <i>×{{ item.formalClassHour }}课时</i> -->
              <i></i>
              <b>￥{{ item.price }}</b>
            </div>
          </div>
        </div>
        <div class="expand" v-if="course.length > 2 && !isExpand" @click="isExpand = true">
          <span>展开</span><img src="../assets/images/expand.svg" alt="" />
        </div>
        <template v-if="course.length > 2 && isExpand">
          <div
            class="pay-body-course-item"
            v-for="(item, index) in course.slice(2, course.length)"
            :key="item.id">
            <div class="pay-body-course-item-l" :style="'background:' + colors[(index + 2) % 5]">
              <img src="../assets/images/tv.svg" alt="" />
            </div>
            <div class="pay-body-course-item-r">
              <span>{{ item.courseName }}</span>
              <div>
                <i>×{{ item.formalClassHour }}课时</i><b>￥{{ item.price }}</b>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="pay-body-card pay-body-payType">
        <div class="pay-body-card-title">支付方式</div>
        <div class="pay-body-payType-list">
          <div
            class="pay-body-payType-list-item"
            v-for="(item, index) in payTypes"
            :key="index"
            @click="activePay = item.value">
            <div class="pay-body-payType-list-item-type">
              <img :src="item.icon" alt="" /><span>{{ item.label }}</span>
            </div>
            <div class="check-item isChecked" v-if="activePay == item.value">
              <img src="../assets/images/checked.svg" alt="" />
            </div>
            <div class="check-item noChecked" v-else></div>
          </div>
        </div>
      </div>
      <div class="pay-body-card pay-body-detail">
        <div class="pay-body-card-title">购买详情</div>
        <div class="pay-body-detail-content">
          <div class="pay-body-detail-content-item">
            <div class="pay-body-detail-content-item-label">购买人：</div>
            <div class="pay-body-detail-content-item-value">
              {{ contractDetail.stuName }}
            </div>
          </div>
          <div class="pay-body-detail-content-item">
            <div class="pay-body-detail-content-item-label">合同编号：</div>
            <div class="pay-body-detail-content-item-value">
              {{ contractDetail.contractCode }}
            </div>
          </div>
          <div class="pay-body-detail-content-item">
            <div class="pay-body-detail-content-item-label">支付方式：</div>
            <div class="pay-body-detail-content-item-value">
              {{ contractDetail.payTypeStr }}
            </div>
          </div>
          <div class="pay-body-detail-content-item">
            <div class="pay-body-detail-content-item-label">留学国家：</div>
            <div class="pay-body-detail-content-item-value">
              {{ contractDetail.applyCountryStr }}
            </div>
          </div>
          <div class="pay-body-detail-content-item">
            <div class="pay-body-detail-content-item-label">创建时间：</div>
            <div class="pay-body-detail-content-item-value">
              {{ contractDetail.createTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <SubmitBar
      :decimal-length="2"
      :safe-area-inset-bottom="true"
      button-text="立即支付"
      :label="isMul == 1 ? '支付金额：' : '本笔支付：'"
      :price="contractDetail.payAmount * 100">
      <!-- <template #default>
        <span v-if="isMul > 1">{{ contractDetail.paySeqNo || 0 }}/{{ $route.query.isMul }} 笔</span>
      </template> -->
      <template #button>
        <Button
          class="submit"
          type="info"
          :loading="isSubmit"
          loading-text="支付中..."
          @click="handleSubmit">
          <img class="pay-loading" slot="loading" src="../assets/images/loading.svg" alt="" />
          立即支付
        </Button>
      </template>
    </SubmitBar>
    <form name="punchout_form" method="post" :action="aliAction">
      <input type="hidden" name="biz_content" v-model="alipayContent" />
      <input type="submit" value="立即支付" style="display: none" />
    </form>
    <van-dialog
      v-model="isConfirm"
      :overlay-style="{ opacity: 0.4 }"
      title="请确认支付是否完成"
      show-cancel-button
      cancel-button-text="遇到问题,重新支付"
      confirm-button-text="已完成支付"
      lock-scroll
      confirm-button-color="#3276FF"
      cancel-button-color="#FA326E"
      :before-close="handlePayStatus"></van-dialog>
    <Overlay :show="isWx" z-index="100" lock-scroll>
      <IsWx
        @changePay="
          isWx = false
          activePay = 1
        "></IsWx>
    </Overlay>
  </div>
</template>

<script>
import { SubmitBar, Button, Dialog, Toast, Overlay } from 'vant'
import ali from '../assets/images/ali.svg'
import wx from '../assets/images/wx.svg'
import { isWx } from '../tool'
import IsWx from '@/components/IsWx'
export default {
  name: 'Pay',
  components: {
    SubmitBar,
    Button,
    IsWx,
    Overlay,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      contractId: this.$route.query.contractId,
      isMul: this.$route.query.isMul, //是否分笔
      isExpand: false, //课程是否展开
      isSubmit: false, //支付loading状态
      isConfirm: false, //确认支付
      colors: ['#7DC065', '#FDC944', '#FF8585', '#43E2CC', '#32C5FF'],
      payTypes: [
        { label: '支付宝支付', value: 0, icon: ali },
        { label: '微信支付', value: 1, icon: wx },
      ],
      activePay: 0,
      course: [],
      contractDetail: {
        contractInfo: {},
        orderInfo: {},
      },
      payChannelList: [],
      contractPayId: '',
      payOrderId: '',
      aliAction: '',
      alipayContent: '',
      isWx: false,
    }
  },
  created() {
    if (!this.$route.query.contractId || this.$route.query.contractId == 'undefined') {
      Toast({
        message: '缺少合同编号',
        forbidClick: true,
        icon: 'warning',
        duration: 3000,
      })
    }
    this.handleGetPayDetail()
  },
  methods: {
    handleInitQueryOrder() {
      const that = this
      this.$http
        .get('/front/pay/qryOrder', {
          payId: this.contractPayId,
        })
        .then(res => {
          if (res.code === 1) {
            const currentOrderStatus = res.data.orderVO.payOrderList.filter(e => {
              return e.paySeqNo == this.contractDetail.paySeqNo
            })[0].payStatus
            if (currentOrderStatus == 1) {
              Toast.success({
                message: '订单已支付',
                forbidClick: true,
                onClose: () => {
                  that.$router.push({
                    path: '/pay-list',
                    query: { contractId: this.contractId },
                  })
                },
              })
            }
          } else {
            this.isSubmit = false
            Toast({
              icon: 'clear',
              forbidClick: true,
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          this.isSubmit = false
          console.log(err)
        })
    },
    handleGetPayDetail() {
      const loading = Toast.loading({
        duration: 2000, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      })
      this.$http
        .get('/front/pay/qryOrderLx', {
          payId: this.$route.query.payOrderNo,
        })
        .then(res => {
          if (res.code === 1) {
            this.course = [...res.data.abroadServiceList]
            this.contractDetail = { ...res.data }
            this.contractPayId = res.data.contractPayId
            this.payOrderId = res.data.payOrderId
            this.handleQueryPayChannel()
            setTimeout(() => {
              loading.clear()
              // this.handleInitQueryOrder()
            }, 600)
          } else {
            loading.clear()
            Toast({
              icon: 'clear',
              forbidClick: true,
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          loading.clear()
          console.log(err)
        })
    },
    handleSubmit() {
      if (isWx()) {
        this.activePay == 0 ? (this.isWx = true) : this.handleInWxAndWxPay()
      } else {
        this.handleGetPayLink()
      }
    },
    handleInWxAndWxPay() {
      //微信浏览器内拉起微信支付 获取参数
      this.isSubmit = true
      if (!this.payOrderId) {
        Toast({
          icon: 'clear',
          message: '支付参数缺失',
          duration: 2000,
        })
        this.isSubmit = false
        return false
      }
      const loading = Toast.loading('支付中...')
      const openid = sessionStorage.getItem('openid')
        ? JSON.parse(sessionStorage.getItem('openid'))
        : ''
      this.$http
        .post('/front/pay/apply', {
          payOrderId: this.payOrderId,
          channelCode: 'wechat_jsapi',
          redirectUrl: `/pay-list?contractId=${encodeURIComponent(this.contractId)}`,
          openid,
        })
        .then(res => {
          loading.clear()
          if (res.code === 1) {
            this.isConfirm = true
            this.handleWxJsBridge(res.data)
          } else {
            this.isSubmit = false
            Toast({
              icon: 'clear',
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          this.isSubmit = false
          loading.clear()
          console.log(err)
        })
    },
    handleWxJsBridge(data) {
      //微信浏览器内拉起微信支付 JSAPI
      const params = {
        ...JSON.parse(data.wxJsp),
      }
      params.package = params.packageStr
      delete params.packageStr
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', { ...params }, function (res) {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        }
      })
    },
    handlePayStatus(action, done) {
      if (action === 'confirm') {
        this.handleQueryPayOrder(done)
      } else if (action === 'cancel') {
        this.isSubmit = true
        done(false)
        if (!isWx()) {
          this.handleGetPayLink()
        } else {
          this.handleInWxAndWxPay()
        }
      }
    },
    handleGetPayLink() {
      this.isSubmit = true
      if (!this.payOrderId) {
        Toast({
          icon: 'clear',
          message: '支付参数缺失',
          duration: 2000,
        })
        this.isSubmit = false
        return false
      }
      const loading = Toast.loading('支付中...')
      this.$http
        .post('/front/pay/apply', {
          payOrderId: this.payOrderId,
          channelCode: this.payChannelList[this.activePay].channelCode,
          redirectUrl: `/pay-list?contractId=${encodeURIComponent(this.contractId)}`,
        })
        .then(res => {
          loading.clear()
          if (res.code === 1) {
            this.isConfirm = true
            if (this.activePay == 0) {
              this.aliAction = res.data.action
              this.alipayContent = res.data.biz_content
              this.$nextTick(() => {
                document.forms[0].submit()
              })
            } else {
              const a = document.createElement('a')
              a.href = res.data.h5_url
              a.click()
            }
          } else {
            this.isSubmit = false
            Toast({
              icon: 'clear',
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          this.isSubmit = false
          loading.clear()
          console.log(err)
        })
    },
    handleQueryPayOrder(done) {
      this.$http
        .get('/front/pay/qryOrder', {
          payId: this.contractPayId,
        })
        .then(res => {
          if (res.code === 1) {
            const currentOrderStatus = res.data.orderVO.payOrderList.filter(e => {
              return e.paySeqNo == this.contractDetail.paySeqNo
            })[0].payStatus
            if (currentOrderStatus == 1) {
              done()
              this.$router.push({
                path: '/pay-list',
                query: { contractId: this.contractId },
              })
            } else {
              Toast({
                icon: 'clear',
                message: '支付未完成',
                duration: 2000,
              })
              done(false)
            }
          } else {
            this.isSubmit = false
            Toast({
              icon: 'clear',
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          this.isSubmit = false
          console.log(err)
        })
    },
    handleQueryPayChannel() {
      this.$http
        .get('/front/pay/qryChannel', {
          payType: this.contractDetail.payType,
        })
        .then(res => {
          if (res.code === 1) {
            this.payChannelList = [...res.data.payChannelList]
          } else {
            Toast({
              icon: 'clear',
              message: res.message,
              duration: 2000,
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::v-deep.pay {
  width: 100%;
  min-height: 100%;
  padding: 0 12px;
  background: #f3f4f7;
  background-image: url('../assets/images/contract-bg.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  font-size: 16px;
  position: relative;
  padding-bottom: 70px;
  .van-submit-bar__bar {
    padding: 0;
    padding-left: 12px;
    & > span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00000d;
    }
    .van-submit-bar__text {
      & > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4f4f4f;
      }
      .van-submit-bar__price {
        font-size: 18px;
        font-weight: 600;
        color: #fa326e;
      }
    }
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    padding: 25px 0;
  }
  &-back {
    display: block;
    width: 20px;
    position: absolute;
    left: 22px;
    top: 22px;
  }
  &-body {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    &-card {
      background: #ffffff;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      &:not(:nth-last-of-type(1)) {
        margin-bottom: 12px;
      }
      &-title {
        width: 100%;
        height: 51px;
        line-height: 51px;
        text-align: left;
        padding: 0 20px;
        border-bottom: 1px solid #eeeeee;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
    &-course {
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 14px 20px;
        &:not(:nth-last-of-type(1)) {
          border-bottom: 1px solid #eee;
        }
        &-l {
          width: 39px;
          height: 39px;
          background: #7dc065;
          border-radius: 39px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 13px;
          img {
            display: block;
            width: 18px;
          }
        }
        &-r {
          flex: 1;
          width: 0;
          span {
            display: block;
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #00000d;
            line-height: 21px;
            margin-bottom: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          div {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            i {
              display: block;
              font-style: normal;
              font-size: 13px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #80858a;
            }
            b {
              font-size: 15px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #00000d;
            }
          }
        }
      }
      .expand {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        height: 44px;
        line-height: 44px;
        text-align: center;
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3276ff;
        }
        img {
          display: block;
          width: 12px;
          margin-left: 5px;
        }
      }
    }
    &-payType {
      width: 100%;
      &-list {
        width: 100%;
        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 17px 28px 17px 21px;
          &-type {
            display: flex;
            flex-wrap: nowrap;
            flex: 1;
            width: 0;
            align-items: center;
            justify-content: flex-start;
            img {
              display: block;
              width: 20px;
              margin-right: 12px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #191919;
            }
          }
          .check-item {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            overflow: hidden;
          }
          .noChecked {
            border: 1px solid #abadb1;
          }
          .isChecked {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
    &-detail {
      &-content {
        width: 100%;
        padding: 14px 20px 20px 20px;
        &-item {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 9px;

          &-label {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #00000d;
            line-height: 19px;
          }
          &-value {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #80858a;
            line-height: 19px;
          }
        }
      }
    }
  }
  @keyframes rotate {
    from {
      transform: rotateZ(-180deg);
    }
    to {
      transform: rotateZ(180deg);
    }
  }
  &-loading {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: rotate 1s linear infinite;
  }
  .submit {
    width: 130px;
    height: 50px;
    background: #3276ff;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0;
  }
  .van-dialog {
    .van-button__text {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}
</style>
